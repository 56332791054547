import config from '../../../config';
import * as constants from './constants';
import { http } from '../../utils/OfflineAxios';

export const getOptions = (lang = config.defaultLang) => async dispatch => {
  try {
    dispatch({
      type: constants.SET_CURRENT_OPTIONS_REQUEST,
    });
    const options = await http.get(
      `${config.apiURL}/wp-json/wp/v2/acf/options?lang=${lang}`,
      {}
    );
    dispatch({
      type: constants.SET_CURRENT_OPTIONS,
      options: options.data || {},
    });
  } catch (error) {
    console.log(error);
  }
};
