import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../../utils/scroll';
import './styles.css';

function Menu(props) {
  function scrollToReservation(link) {
    if (typeof window !== 'undefined') {
      const item = document.getElementById(link);
      scrollToItem(item, 150);
    }
  }

  function renderLink(col) {
    if (col.link[0] === '/') {
      return (
        <Link
          to={col.link}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else if (!props.isHome) {
      return (
        <Link
          to={`/#${col.link}`}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
        >
          {col.title}
        </Link>
      );
    } else {
      return (
        <a
          href={`#`}
          className={props.location.match(col.link) ? 'active' : 'notactive'}
          onClick={() => scrollToReservation(col.link)}
        >
          {col.title}
        </a>
      );
    }
  }

  return (
    <ul className={'wrap clearfix'}>
      <li
        className={'logo'}
        style={{
          animationDelay: `1s`,
          animationDuration: `2s`,
        }}
      >
        <Link to={''} />
      </li>
      <li className={'menu-items'}>
        <ul>
          {props.content &&
            props.content.map((col, index) => (
              <li
                key={index}
                className={''}
                style={{
                  animationDelay: `${index + 0.1}s`,
                  animationDuration: `2s`,
                }}
              >
                {renderLink(col)}
              </li>
            ))}
          {/* <li className={'shop'}>
        <Link to={''} />
      </li> */}
        </ul>
      </li>
    </ul>
  );
}

export default Menu;
