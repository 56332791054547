import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Slider from 'react-slick';
import { Container } from 'reactstrap';

import Menu from '../Menus/Menu';
import MobileMenu from '../Menus/MobileMenu';
import './styles.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stikyClass: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === 'set' && this.props.status !== prevProps.status) {
      this.setState({
        headerClass: prevProps.isHome ? 'header' : 'header inner',
      });
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    const currentHeight =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (currentHeight > 50 && !this.state.handleScroll) {
      this.setState({ handleScroll: true });
    } else if (currentHeight < 50 && this.state.handleScroll) {
      this.setState({ handleScroll: false });
    }
  }

  render() {
    const { head } = this.props.content;
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 1800,
      fade: true,
      autoplay: head && head.length > 1,
      // autoplay: 0,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className={`headerWrap ${!this.props.isHome && 'not-home'}`}>
        <div
          className={`menus clearfix ${this.state.handleScroll ? 'fixed' : ''}`}
        >
          <Container>
            <MobileMenu
              isHome={this.props.isHome}
              menu={this.props.topmenu}
              location={this.props.current}
              submenu={this.props.submenu}
              lang={this.props.lang}
            />

            <Menu
              isHome={this.props.isHome}
              content={this.props.topmenu}
              location={this.props.current}
            />
          </Container>
        </div>
        <Container className={'full-height'}>
          <div
            className={`${this.props.isHome ? 'header' : 'header inner'}`}
            style={{
              animationDelay: `1.5s`,
              animationDuration: `2s`,
            }}
          >
            {(this.props.isHome ||
              (!this.props.isHome && head && !head[0].title)) && (
              <div className={'overlay-bars'} />
            )}
            {head && (
              <Slider {...settings}>
                {head.map(
                  (slide, index) =>
                    slide.slide && (
                      <div key={index} className={'header-wrapper'}>
                        <div
                          className={'header-slide'}
                          style={{
                            backgroundImage: `url('${slide.slide.sizes &&
                              slide.slide.sizes.large}')`,
                          }}
                        />
                        {slide.title && (
                          <div className={'box-elemtns'}>
                            {slide.title && (
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html: slide.title,
                                }}
                              />
                            )}
                            {slide.sub_title && (
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: slide.sub_title,
                                }}
                              />
                            )}
                            {slide.text && (
                              <p
                                dangerouslySetInnerHTML={{ __html: slide.text }}
                              />
                            )}
                            {slide.button && (
                              <a className={'action'} href={slide.link}>
                                {slide.button}
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    )
                )}
              </Slider>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  topmenu: state.menus.topmenu,
  submenu: state.menus.submenu,

  status: state.content.status,
  content: state.content.currentContent && state.content.currentContent.acf,
});

const mapDispatchToProps = dispatch => bindActionCreators({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  content: PropTypes.object,
  topmenu: PropTypes.array,
  submenu: PropTypes.array,
};

Header.defaultProps = {
  content: {
    slides: [],
  },
  topmenu: [],
  submenu: [],
};
