import React from 'react';
import { Route, Switch } from 'react-router-dom';
import config from '../../config';

import Loadable from 'react-loadable';
import NotFound from './not-found';

const Content = Loadable({
  loader: () => import(/* webpackChunkName: "content" */ './content'),
  loading: () => null,
  modules: ['content'],
});

const languages = config.languages.join('|');

export default () => (
  <Switch>
    <Route exact path={`/:lang(${languages})?/NotFound`} component={NotFound} />
    <Route
      exact
      path={`/:lang(${languages})?/locations/:content`}
      render={props => <Content type={'locations'} {...props} />}
    />

    <Route
      exact
      path={`/:lang(${languages})?/news/:content`}
      render={props => <Content type={'news'} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/partners/:content`}
      render={props => <Content type={'partners'} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/publications/types/:category`}
      render={props => <Content type={'publications'} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/publications/:content`}
      render={props => <Content type={'publications'} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/projects/:content`}
      render={props => <Content type={'projects'} {...props} />}
    />

    <Route
      path={`/:lang(${languages})?/consumer/types/:category`}
      render={props => <Content type={'consumer'} {...props} />}
    />
    <Route
      path={`/:lang(${languages})?/consumer/:content`}
      render={props => <Content type={'consumer'} {...props} />}
    />

    <Route exact path={`/:lang(${languages})?/:content?`} component={Content} />
    <Route exact path={`*`} component={NotFound} />
  </Switch>
);
